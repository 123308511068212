import SuccessfullySent from '@/assets/SuccessfullySent.png';
import Image from '@/components/Image';
import Text from '@/components/Text';

const SuccessRequestSend = () => {
  return (
    <div className="flex flex-col gap-7 text-neutral-1000">
      <div className="flex flex-col gap-2">
        <Text variant="h2" className="font-semibold">
          Thank You!
        </Text>
        <Text variant="h5" className="font-semibold">
          Your request has been submitted successfully.
        </Text>
      </div>
      <Text>
        Please wait for an email from our team to confirm if we have received
        all the necessary information.
      </Text>
      <Image src={SuccessfullySent} alt="Successfully Sent" className="w-1/2" />
    </div>
  );
};

export default SuccessRequestSend;
