import NextImage from 'next/image';

const Image = ({ ...props }: any) => {
  const imageLoader = ({ src, width, quality }: any) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return <NextImage loader={imageLoader} {...props} />;
};

export default Image;
